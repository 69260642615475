<template>
  <section class="faq">
    <v-container class="mb-16">
      <p class="text-h5 text-lg-h4 font-weight-bold text-center mt-16 mb-8">Συχνές ερωτήσεις</p>

      <v-divider class="mx-auto w-25 mb-16"></v-divider>

      <v-row>
        <v-col>

          <!-- Courses -->
          <!-- Subscriptions -->
          <p class="pl-4 pt-8">Προγράμματα γυμναστικής</p>
          <v-expansion-panels focusable>
            <v-expansion-panel>
              <v-expansion-panel-header>
                Ποια τα οφέλη της πλατφόρμας σας;
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pt-4 text-justify">
                Η πλατφόρμα μας, σε αντίθεση με μια συνδρομή γυμναστηρίου, προσφέρει διαδικτυακά προγράμματα γυμναστικής
                τα οποία μπορείτε να τα ακολουθήσετε με τον δικό σας ρυθμό, χωρίς άγχος, όποια ώρα της ημέρας επιθυμείτε
                και από όπου επιθυμείτε.
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                Ποια προγράμματα γυμναστικής προσφέρει η πλατφόρμα;
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pt-4 text-justify">
                Στην πλατφόρμα υπάρχουν 3 προγράμματα εκγύμνασης<br><br>
                <ol>
                  <li>Ενδυμάμωση σώματος</li>
                  <li>HIIT</li>
                  <li>Pilates</li>
                </ol>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                Ποια είναι η διάρκεια του κάθε προγράμματος;
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pt-4 text-justify">
                Η διάρκεια του προγράμματος είναι από 20 έως και 40 λεπτά.
                <br><br>
                Μέσα σε αυτή περιέχεται το ζέσταμα, η άσκηση και η αποθεραπεία.
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                Θα χρειαστώ ειδικό εξοπλισμό;
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pt-4 text-justify">
                Όχι απαραίτητα. Η επένδυση σε εξοπλισμό είναι θεμιτή, αλλά όχι απαραίτητη!
                <br><br>
                Στα προγράμματα θα δωθούν μια ή περισσότερες εναλλακτικές για τον εξοπλισμό που θα χρησιμοποιηθεί.
                Για παράδειγμα, αντί ενός μονόκιλου αλτήρα μπορούμε να χρησιμοποιήσουμε ένα γεμάτο μπουκάλι νερό.
                <br><br>
                Αν θέλετε να προμηθευτείτε εξοπλισμό μπορείτε να πάρετε αλτήρες 2kg - 4kg και λάστιχα αντιστάσεων.
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                Ποιος είναι ο βαθμός δυσκολίας των προγραμμάτων;
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pt-4 text-justify">
                Στην Maridela γνωρίζουμε την ανάγκη για μια χαλαρή αρχή και την αύξηση δυσκολίας που απαιτείται στην συνέχεια.
                <br><br>
                Όλες οι ασκήσεις που θα γίνουν κατά την διάρκεια του προγράμματος θα έχουν εναλλακτικούς τρόπους εκτέλεσης
                για κάθε βαθμό δυσκολίας, είτε είσαι αρχάριος είτε προχωρημένος θα λάβεις την σωστή εκγύμναση.
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                Μπορώ να γυμναστώ στο σπίτι;
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pt-4 text-justify">
                Τα προγράμματα είναι σχεδιασμένα ώστε να μπορείτε να τα ακολουθήσετε όπου εσείς θέλετε.
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                Θα χρειαστεί επιπρόσθετη αερόβια άσκηση πέρα των προγραμμάτων;
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pt-4 text-justify">
                Σύμφωνα με έρευνες 20 έως 30 λεπτά καθημερινής άσκησης αρκούν για την διατήρηση του φυσιολογικού
                σωματικού βάρους καθώς και για την ενεργοποίηση του μεταβολισμού.
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                Σε περίπτωση εγκυμοσύνης μπορώ να χρησιμοποιήσω την πλατφόρμα;
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pt-4 text-justify">
                <strong>Πρώτα από όλα συμβουλευόμαστε τον γιατρό μας.</strong> Αν και εφόσον μας το επιτρέπει μπορούμε να ξεκινήσουμε
                γυμναστική. Καλό θα ήταν αν δεν έχουμε γυμναστεί για αρκετό καιρό να το αποφύγουμε το πρώτο
                3μηνο της κύησης.<br><br>
                Τα προγράμματα των 3 κατηγοριών μας δεν είναι σχεδιασμένα για να εκτελούνται από εγκύους. <strong>Ωστόσο θα υπάρξει
                σύντομα μια 4η κατηγορία μόνο για τις εγκυούλες μου!</strong>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <!-- Subscriptions -->
          <p class="pl-4 pt-16">Συνδρομή</p>
          <v-expansion-panels focusable>
            <v-expansion-panel>
              <v-expansion-panel-header>
                Τι μου παρέχει η συνδρομή μου;
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pt-4 text-justify">
                Στην Maridela πιστεύουμε ότι πρέπει να υπάρχει ποικιλία άσκησης στην καθημερινότητα μας, για αυτό η
                συνδρομή σας παρέχει απεριόριστη πρόσβαση σε όλα τα προγράμματα γυμναστικής που παρέχει η υπηρεσία μας.
                <br><br>
                Δεν χρειάζεται να αγοράσετε ένα ένα τα προγράμματα ξεχωριστά.
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                Η συνδρομή μου ενεργοποιείται απευθείας μετά την αγορά της;
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pt-4 text-justify">
                Αν η αγορά έχει γίνει μέσω PayPal ή πιστωτικής / χρεωστικής κάρτας η συνδρομή ενεργοποιείται απευθείας.
                <br><br>
                Σε περίπτωση αγοράς μέσω τραπεζικής κατάθεσης η συνδρομή ενεργοποιείται μόλις γίνει η επιβεβαίωση πληρωμής
                από μεριά μας.
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                Η συνδρομή μου ενεργοποιείται απευθείας μετά την ανανέωση της;
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pt-4 text-justify">
                Αν η ανανέωση έχει γίνει μέσω PayPal ή πιστωτικής / χρεωστικής κάρτας η συνδρομή ενεργοποιείται απευθείας.
                <br><br>
                Σε περίπτωση ανανέωσης μέσω τραπεζικής κατάθεσης η συνδρομή ενεργοποιείται μόλις γίνει η επιβεβαίωση πληρωμής
                από μεριά μας.
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                Η συνδρομή μου ανανεώνεται αυτόματα;
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pt-4 text-justify">
                Όχι. Προς το παρόν δεν υπάρχει λειτουργία αυτόματης ανανέωσης της συνδρομής σας.
                <br><br>
                Θα λάβετε αυτοματοποιημένα email υπενθύμισης στην ηλεκτρονική διεύθυνση που έχετε δηλώσει κατά την
                εγγραφή σας 3 ημέρες πριν την λήξη της συνδρομής όπως και την ημέρα της λήξης της.
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                Υπάρχει υπενθύμιση ανανέωσης συνδρομής;
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pt-4 text-justify">
                Ναι. Θα λάβετε αυτοματοποιημένα email υπενθύμισης στην ηλεκτρονική διεύθυνση που έχετε δηλώσει κατά την
                εγγραφή σας 3 ημέρες πριν την λήξη της συνδρομής όπως και την ημέρα της λήξης της.
                <br><br>
                Αν η συνδρομή έχει λήξει και συνδεθείτε στο λογαριασμό σας θα υπάρχει στο πάνω μέρος της σελίδας ένα
                ενημερωτικό σημείωμα, το οποίο θα σας καθοδηγήσει για την ανανέωση της.
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                Τι συμβαίνει με το πέρας της συνδρομής μου;
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pt-4 text-justify">
                Με την λήξη της συνδρομής δεν έχετε πλέον πρόσβαση στα προγράμματα γυμναστικής.
                <br><br>
                Ο λογαριασμός σας παραμένει ενεργοποιημένος. Μπορείτε ανά πάσα στιγμή να συνδεθείτε και να ανανεώσετε την
                συνδρομή σας.
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <!-- Payments -->
          <p class="pl-4 pt-16">Πληρωμές</p>
          <v-expansion-panels focusable>
            <v-expansion-panel>
              <v-expansion-panel-header>
                Ποιοι τρόποι πληρωμής υποστηρίζονται;
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pt-4 text-justify">
                Η αγορά ή ανανέωση της συνδρομής σας μπορεί να γίνει με τους παρακάτω τρόπους.
                <br><br>
                <strong><v-icon>mdi-square-small</v-icon>Paypal</strong>
                <br><br>
                <strong><v-icon>mdi-square-small</v-icon>Χρεωστική ή Πιστωτική κάρτα</strong>
                <br><br>
                <strong><v-icon>mdi-square-small</v-icon>Τραπεζική κατάθεση</strong>
                <br>
                <p class="mt-4 pl-8">
                  ΔΕΛΑΚΗ ΚΑΤΙΝΑ ΑΘΑΝΑΣΙΟΣ
                  <br>
                  Εθνική Τράπεζα    875/742455-82
                  <br>
                  IBAN        GR53 0110 8750 0000 8757 4245 582
                </p>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                Πως θα χρησιμοποιηθούν τα στοιχεία της κάρτας μου;
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pt-4 text-justify">
                Οι πληρωμές μέσω Πιστωτικής / Χρεωστικής κάρτας πραγματοποιούνται μέσα από το ασφαλές περιβάλλον της PayPal.
                <br><br>
                <strong>Τα στοιχεία της κάρτας σας δεν αποθηκεύονται και δεν επεξεργάζονται από το σύστημα μας.</strong>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  created () {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }
};
</script>
